
import "../static/Login.css";
import {useState} from 'react';

const Login = ()=>{
    
    const [userName,setUserName] = useState("email@website.com");
    const [password,setPassword] = useState("123456");

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="login-side-image col-md-8 col-lg-8 col-sm-12"></div>
                <div className="login-form-container col-md-4 col-lg-4 col-sm-12">
                    <form className="form">
                        <div className="welcome-container">
                            <h1>Welcome to contronika tech</h1>
                            <h3 style={{justifyContent: 'center',fontSize:'25px'}}>Sign in to your account</h3>
                        </div>
                        <div className="form-group">
                            <label>Username</label>
                            <input 
                              value={userName} 
                              type="text" 
                              className="form-control" 
                              onChange={(e)=>setUserName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input 
                              value={password} 
                              type="password" 
                              className="form-control" 
                              onChange={(e)=>setPassword(e.target.password)}
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary btn-lg submit-btn">
                                Submit
                            </button>
                        </div>
                        <div className="form-group">
                            <a href="forgotten">Forgot password?</a><br/>
                            <a href="signup">Signup if you have not registered</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;