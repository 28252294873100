import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{(props.data.title)}</h2>
          <p>{props.data.intro}</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" style={{paddingBottom: '50px',boxShadow:'0px 1px 1px 2px'}}>
                  {" "}
                  <img className="service-image" src={d.icon} />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                  <button className="btn btn-danger btn-circle">Learn more</button>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
